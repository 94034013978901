import { useState, useEffect, useContext } from "react";
import TextCurrency from "../components/TextCurrency";
import { View, ScrollView } from "react-native";
import InputSpinner from "react-native-input-spinner";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../initSupabase";
import { AuthContext } from "../provider/AuthProvider";
import {
  Layout,
  TopNav,
  Text,
  themeColor,
  Section,
  SectionContent,
  Button,
} from "react-native-rapi-ui";
import { FontAwesome5 } from "@expo/vector-icons";

export default function ({
  route,
  navigation,
}: NativeStackScreenProps<MainStackParamList, "StockWithdraw">) {
  const { itemId } = route.params;
  const { user, session } = useContext(AuthContext);
  const [userAsset, setUserAsset] = useState({
    total_unit: 0,
    stock: {
      code: "",
      name: "",
    },
  });
  const [unit, setUnit] = useState(0);
  const [withdraw, setWithdraw] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [max, setMax] = useState(0);

  const fetchUserAsset = async () => {
    const { data: userAsset, error } = await supabase
      .from("user_stock")
      .select("*,stock(id,code,name)")
      .eq("id", itemId)
      .single();
    if (error) {
      console.log("error", error);
    } else {
      setMax(Math.floor(userAsset?.total_unit));
      setUnit(Math.floor(userAsset?.total_unit));
      setUserAsset(userAsset);
      fetchAssetPrice(userAsset?.asset_id);
    }
  };

  const fetchAssetPrice = async (id: string) => {
    const { data: price, error } = await supabase
      .from("stock_price")
      .select("price")
      .eq("stock_id", id)
      .single();
    if (error) {
      console.log("error", error);
    } else {
      setUnitPrice(price.price);
      setWithdraw(price.price * 100);
    }
  };

  const withdrawAction = async () => {
    let user = session?.user?.id;
    let { data, error } = await supabase.rpc("withdraw", {
      stock: unit,
      userid: user,
      user_stock_id: itemId,
      user_wallet: "",
    });

    if (error) {
      console.log("error", error);
    } else if (data == 1) {
      alert("Permintaan penarikan saham gagal dibuat");
    } else if (data == 0) {
      alert("Permintaan penarikan saham berhasil dibuat");
      navigation.navigate("Home");
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserAsset();
    }
  }, []);

  return (
    <Layout>
      <TopNav
        middleContent={"Tarik"}
        leftContent={
          <FontAwesome5
            name="chevron-left"
            size={20}
            color={themeColor.black100}
          />
        }
        leftAction={() => navigation.goBack()}
      />
      <View>
        <ScrollView style={{ flexGrow: 1 }}>
          <View style={{ margin: 12 }}>
            <Section>
              <SectionContent>
                <View
                  style={{ flexDirection: "row", justifyContent: "flex-start" }}
                >
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "space-around",
                    }}
                  >
                    <Text style={{ fontSize: 12 }}>
                      {userAsset?.stock?.code}
                    </Text>
                    <Text>{userAsset?.stock?.name}</Text>
                  </View>
                </View>
              </SectionContent>
            </Section>
          </View>

          <View style={{ margin: 12 }}>
            <Section>
              <SectionContent>
                <View
                  style={{
                    flexDirection: "column",
                    marginBottom: 12,
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: 12 }}>Jumlah Penarikan</Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    marginBottom: 12,
                    justifyContent: "space-between",
                  }}
                >
                  <InputSpinner
                    type={"real"}
                    step={1}
                    max={max}
                    min={1}
                    colorMax={"#f04048"}
                    colorMin={"#40c5f4"}
                    disabled={userAsset.total_unit == unit || unit == 0}
                    value={unit}
                    onChange={(num) => {
                      setUnit(Number(num));
                      setWithdraw(Number(num) * unitPrice * 100);
                    }}
                  />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 12,
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: 12 }}>Total Unit</Text>
                  <Text style={{ fontSize: 12 }}>
                    {userAsset?.total_unit} Lot
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 12,
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={{ fontSize: 12 }}>Harga Saat ini</Text>
                  <TextCurrency
                    color={"black"}
                    value={unitPrice}
                    code="IDR"
                    fontSize={12}
                  ></TextCurrency>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 12,
                  }}
                >
                  <Text style={{ fontSize: 12 }}>Jumlah yang ditarik</Text>
                  <TextCurrency
                    color={"black"}
                    value={withdraw}
                    code="IDR"
                    fontSize={12}
                  ></TextCurrency>
                </View>
              </SectionContent>
            </Section>
          </View>

          <View style={{ margin: 12 }}>
            <Section>
              <SectionContent>
                <Button
                  color="#1a91d6"
                  text="Tarik"
                  disabled={unit < 1}
                  onPress={() => {
                    withdrawAction();
                  }}
                ></Button>
              </SectionContent>
            </Section>
          </View>
        </ScrollView>
      </View>
    </Layout>
  );
}
