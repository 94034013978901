import { MainStackParamList } from "../types/navigation";
import { useState, useContext, useEffect } from "react";
import { supabase } from "../initSupabase";
import { AuthContext } from "../provider/AuthProvider";
import AssetItem from "../components/AssetItem";
import { useIsFocused } from "@react-navigation/native";
import { Text, TouchableOpacity, FlatList, View, Image } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Layout, Section, SectionContent, TopNav } from "react-native-rapi-ui";
import { FontAwesome5 } from "@expo/vector-icons";

import "react-native-url-polyfill/auto";
import TextCurrency from "../components/TextCurrency";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MainTabs">) {
  const Tab = createMaterialTopTabNavigator();
  const isFocused = useIsFocused();
  const { user, session } = useContext(AuthContext);
  const [userAsset, setUserAsset] = useState([
    {
      id: "",
      total_unit: 0,
      stock: {
        code: "",
        name: "",
      },
    },
  ]);
  const [assetTotal, setAssetTotal] = useState(0);

  const fetchUserAsset = async () => {
    const ac = new AbortController();
    const { data: userAsset, error } = await supabase
      .from("user_stock")
      .select("*,stock(code,name,price,icon)")
      .gt("total_unit", 0)
      .abortSignal(ac.signal);
    if (error) console.log("error", error);
    else setUserAsset(userAsset);
  };

  const fetchTotalAsset = async () => {
    const ac = new AbortController();
    const { data: price, error } = await supabase
      .rpc("get_total_asset", {
        userid: session?.user.id,
      })
      .abortSignal(ac.signal);
    if (error) {
      console.log("error", error);
    } else {
      setAssetTotal(price);
    }
  };

  const _handleClickAsset = (item) => {
    navigation.navigate("StockDetail", { itemId: item.id });
  };

  const _renderAsset = ({ item }) => {
    return (
      <TouchableOpacity key={item.id} onPress={() => _handleClickAsset(item)}>
        <Section key={item.id}>
          <SectionContent>
            <AssetItem asset={item} key={item.id}></AssetItem>
          </SectionContent>
        </Section>
      </TouchableOpacity>
    );
  };

  const __separator = () => {
    return <View style={{ width: "100%", height: 10 }}></View>;
  };

  const _renderEmpty = () => {
    return (
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 300,
          paddingTop: 10,
          paddingBottom: 10,
          borderColor: "black",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("Merchant");
          }}
        >
          <FontAwesome5 name="shopping-cart" size={48} color="#1a91d6" />
          <Text>Ayo Belanja</Text>
        </TouchableOpacity>
      </View>
    );
  };

  useEffect(() => {
    if (user) {
      fetchUserAsset();
      fetchTotalAsset();
    }
  }, [isFocused]);

  return (
    <Layout>
      <TopNav
        middleContent={
          <Image
            style={{ height: 150, width: 150 }}
            source={require("../../assets/icon.png")}
          ></Image>
        }
      />
      <Section
        style={{
          marginHorizontal: 10,
          marginVertical: 10,
        }}
      >
        <SectionContent
          style={{
            alignItems: "center",
            padding: 10,
          }}
        >
          <View style={{ marginBottom: 10 }}>
            <Text>Total Portofolio</Text>
          </View>
          <TextCurrency
            code="IDR"
            color="black"
            fontSize={24}
            value={assetTotal}
          ></TextCurrency>
        </SectionContent>
      </Section>
      <FlatList
        data={userAsset}
        contentContainerStyle={{
          overflow: "hidden",
          marginHorizontal: 5,
          marginVertical: 5,
          flexGrow: 1,
        }}
        ItemSeparatorComponent={__separator}
        renderItem={_renderAsset}
        ListEmptyComponent={_renderEmpty}
        keyExtractor={(item) => item.id}
      />
    </Layout>
  );
}
