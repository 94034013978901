import React from "react";
import Moment from "moment";
import { View } from "react-native";
import { Text, Avatar } from "react-native-rapi-ui";
import "react-native-url-polyfill/auto";
import TextCurrency from "./TextCurrency";

export default (props: {
  asset: {
    total_asset: number;
    total_unit: number;
    total_reward: number;
    created_at: Date;
    merchant: { name: string };
    stock: {
      code: string;
      name: string;
      icon: string;
    };
    transaction_id: string;
  };
}) => {
  const _dateFormat = (date: Date) => {
    Moment.locale("en");
    let dt = date;
    return Moment(dt).format("DD MMM yyyy");
  };
  return (
    <View
      style={{
        flex: 3,
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <View style={{ margin: 10 }}>
          <Avatar
            source={{ uri: props.asset.stock.icon }}
            size="sm"
            shape="round"
          />
        </View>

        <View
          style={{
            flex: 2,
            marginTop: 10,
            flexDirection: "column",
          }}
        >
          <View>
            <Text style={{ fontSize: 12 }}>{props.asset.stock.code}</Text>
            <Text style={{ fontSize: 10 }}>
              Reward dari {props.asset.merchant.name}
            </Text>
          </View>
        </View>
        <View
          style={{
            marginTop: 10,
            flex: 2,
            flexDirection: "column",
          }}
        >
          <View style={{ alignItems: "flex-end" }}>
            <Text style={{ fontSize: 10 }}>
              {_dateFormat(props.asset.created_at)}
            </Text>

            <View>
              <TextCurrency
                code="IDR"
                color="black"
                key={"total_rewrad"}
                value={props.asset.total_reward}
                fontSize={12}
              ></TextCurrency>
            </View>
            <View>
              <Text style={{ fontSize: 10 }}>{props.asset.total_unit} Lot</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
