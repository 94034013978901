import "react-native-url-polyfill/auto";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { createClient } from "@supabase/supabase-js";

const options = {
  auth: {
    storage: AsyncStorage,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
  global: {
    headers: {
      "x-my-custom-header": "my-app-name",
      "Access-Control-Allow-Origin": "*",
    },
  },
};

const SUPABASE_URL = "https://izfqhpgmxlvsklljlhfg.supabase.co";
const SUPABASE_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml6ZnFocGdteGx2c2tsbGpsaGZnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODgzNzY2MDcsImV4cCI6MjAwMzk1MjYwN30.qJ6JwbJeY_42-3DW72qvPkw9aS95Qu9gOYAL6iJXgwA";
// Better put your these secret keys in .env file
export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY, options);
