import { MainStackParamList } from "../types/navigation";
import { useState, useContext, useEffect } from "react";
import { supabase } from "../initSupabase";
import { AuthContext } from "../provider/AuthProvider";
import { Text, FlatList, View } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Layout, Section, SectionContent, TopNav } from "react-native-rapi-ui";
import { useIsFocused } from "@react-navigation/native";
import "react-native-url-polyfill/auto";
import { FontAwesome5 } from "@expo/vector-icons";

import HistoryIten from "../components/HistoryItem";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "History">) {
  const { user, session } = useContext(AuthContext);
  const [userReward, setUserReward] = useState([]);
  const isFocused = useIsFocused();
  const fetchHistory = async () => {
    const ac = new AbortController();
    let query = supabase
      .from("history")
      .select("id,status,amount,created_at,type_trx");

    let { data: userReward, error } = await query.abortSignal(ac.signal);
    if (error) console.log("error", error);
    else setUserReward(userReward!);
  };

  const _renderValue = ({ item }) => {
    return (
      <Section key={item.id}>
        <SectionContent>
          <HistoryIten history={item} />
        </SectionContent>
      </Section>
    );
  };

  const __separator = () => {
    return <View style={{ width: "100%", height: 10 }}></View>;
  };

  const _renderEmpty = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          minHeight: 60,
          paddingTop: 10,
          paddingBottom: 10,
          borderColor: "black",
        }}
      >
        <Text>Belum ada riwayat</Text>
      </View>
    );
  };
  useEffect(() => {
    if (user) {
      fetchHistory();
    }
  }, [isFocused]);
  return (
    <Layout>
      <TopNav
        middleContent={"Riwayat"}
        leftContent={
          <FontAwesome5 name="chevron-left" size={20} color={"black"} />
        }
        leftAction={() => navigation.goBack()}
      />
      <FlatList
        contentContainerStyle={{
          overflow: "hidden",
          margin: 10,
          flexGrow: 1,
        }}
        data={userReward}
        ItemSeparatorComponent={__separator}
        renderItem={_renderValue}
        ListEmptyComponent={_renderEmpty}
        keyExtractor={(item) => item.id}
      />
    </Layout>
  );
}
