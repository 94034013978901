import { useState, useContext, useEffect } from "react";
import { View, ScrollView } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../initSupabase";
import { AuthContext } from "../provider/AuthProvider";
import {
  Layout,
  TopNav,
  Avatar,
  Text,
  themeColor,
  Section,
  SectionContent,
  Button,
  TextInput,
} from "react-native-rapi-ui";
import { FontAwesome5 } from "@expo/vector-icons";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "Withdraw">) {
  const { user, session } = useContext(AuthContext);
  const [total, setTotal] = useState(10000);
  const [wallet, setWallet] = useState({ type: "OVO", phone: "xxxx" });

  const fetchUserWallet = async () => {
    let { data, error } = await supabase
      .from("wallets")
      .select("phone,type,id")
      .eq("user_id", session?.user.id)
      .limit(1)
      .single();
    if (!error) {
      setWallet(data);
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserWallet();
    }
  }, []);

  const withdrawAction = async () => {
    let { data, error } = await supabase.rpc("b_withdraw", {
      total: total,
      userid: session?.user.id,
    });
    if (error) {
      console.log(error);
      alert("Tarik saldo gagal");
    } else {
      alert("permintaan tarik saldo berhasil dibuat");
      navigation.navigate("Home");
    }
  };

  return (
    <Layout>
      <TopNav
        middleContent={"Tarik Saldo"}
        leftContent={
          <FontAwesome5
            name="chevron-left"
            size={20}
            color={themeColor.black100}
          />
        }
        leftAction={() => navigation.goBack()}
      />
      <View>
        <ScrollView style={{ flexGrow: 1 }}>
          <View style={{ margin: 12 }}>
            <Section>
              <SectionContent>
                <View style={{ marginVertical: 10 }}>
                  <Text style={{ fontSize: 12 }}>Transfer</Text>
                </View>
                <View>
                  <TextInput
                    leftContent={wallet.type}
                    read-only={true}
                    value={wallet.phone}
                  ></TextInput>
                </View>
              </SectionContent>
            </Section>
          </View>

          <View style={{ margin: 12 }}>
            <Section>
              <SectionContent>
                <View>
                  <Text style={{ marginBottom: 10 }}>Jumlah yang ditarik</Text>
                  <TextInput
                    leftContent={<Text>Rp</Text>}
                    value={total}
                    placeholder="10000"
                    onChangeText={(val) => setTotal(val)}
                  ></TextInput>
                </View>
                <Text>Minimal Penarikan Rp 10.000</Text>
              </SectionContent>
            </Section>
          </View>

          <View style={{ margin: 12 }}>
            <Section>
              <SectionContent>
                <Button
                  color="#1a91d6"
                  text="Tarik"
                  onPress={() => {
                    withdrawAction();
                  }}
                ></Button>
              </SectionContent>
            </Section>
          </View>
        </ScrollView>
      </View>
    </Layout>
  );
}
