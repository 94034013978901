import React, { useState } from "react";
import base64 from 'react-native-base64';
import {
  ScrollView,
  TouchableOpacity,
  View,
  Image,
  StyleSheet,
  KeyboardAvoidingView,
} from "react-native";
import { supabase } from "../../initSupabase";
import { AuthStackParamList } from "../../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Layout,
  Text,
  TextInput,
  Button,
  themeColor,
} from "react-native-rapi-ui";

export default function ({
  navigation,
}: NativeStackScreenProps<AuthStackParamList, "Register">) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    email: "",
    name: "",
  });

  const styles = StyleSheet.create({
    error: {
      color: "red",
      marginBottom: 5,
      marginTop: 15,
    },
  });

  const register = async () => {
    const errorList = [];
    const errors = {
      email: "",
      password: "",
    };
    setLoading(true);

    if (!email) {
      errors.email = "Email harus di isi";
      errorList.push(1);
    }

    if (!password) {
      errors.password = "Password harus di isi";
      errorList.push(1);
    }

    setErrors(errors);
    if (errorList.length == 0) {
      let hp= base64.encode('083815795776');
      let saham=1;
      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
        options: {
          data: {
            xx: hp,
            saham:1,
          }
        }
      });
      if (!error) {
        setLoading(false);
        alert("Pendaftaran berhasil");
        navigation.navigate("Login");
      }
      if (error) {
        setLoading(false);
        alert(error.message);
      }
    }
    setLoading(false);
  };
  return (
    <KeyboardAvoidingView behavior="height" enabled style={{ flex: 1 }}>
      <Layout>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
          }}
        >
          <View
            style={{
              flex: 3,
              paddingHorizontal: 20,
              paddingBottom: 20,
              backgroundColor: themeColor.white,
            }}
          >
           <View style={{ alignItems: "center" }}>
              <Image style={{height:100,width:300}} source={require("../../../assets/icon.png")}></Image>
            </View>

            <Text style={{ marginTop: 15 }}>Email</Text>
            <TextInput
              containerStyle={{ marginTop: 15 }}
              placeholder="Masukan email"
              value={email}
              autoCapitalize="none"
              autoCompleteType="off"
              autoCorrect={false}
              keyboardType="email-address"
              onChangeText={(text) => setEmail(text)}
            />

            {errors.email && <Text style={styles.error}>{errors.email}</Text>}

            <Text style={{ marginTop: 15 }}>Sandi</Text>
            <TextInput
              containerStyle={{ marginTop: 15 }}
              placeholder="Masukan password"
              value={password}
              autoCapitalize="none"
              autoCompleteType="off"
              autoCorrect={false}
              secureTextEntry={true}
              onChangeText={(text) => setPassword(text)}
            />

            {errors.password && (
              <Text style={styles.error}>{errors.password}</Text>
            )}

            <Button
              text={loading ? "Loading" : "Buat akun"}
              onPress={() => {
                register();
              }}
              style={{
                marginTop: 20,
              }}
              color="#1a91d6"
              disabled={loading}
            />

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 15,
                justifyContent: "center",
              }}
            >
              <Text size="md">Sudah punya akun ?</Text>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("Login");
                }}
              >
                <Text
                  size="md"
                  fontWeight="bold"
                  style={{
                    marginLeft: 5,
                  }}
                >
                  Login
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </Layout>
    </KeyboardAvoidingView>
  );
}
