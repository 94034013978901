import React from "react";
import { View } from "react-native";
import { Text, Avatar } from "react-native-rapi-ui";
import "react-native-url-polyfill/auto";
import TextCurrency from "./TextCurrency";

export default (props: {
  asset: {
    total_unit: number;
    stock: {
      code: "";
      icon: string;
      name: string;
      price: number;
    };
  };
}) => {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "flex-start",
      }}
    >
      <View style={{ margin: 10 }}>
        <Avatar
          source={{ uri: props.asset.stock.icon }}
          size="sm"
          shape="round"
          resizeMode="repeat"
        />
      </View>

      <View
        style={{
          flex: 2,
          marginTop: 10,
          flexDirection: "column",
        }}
      >
        <View>
          <Text style={{ fontSize: 12 }}>{props.asset.stock.code}</Text>
        </View>
        <View>
          <Text style={{ fontSize: 12 }}>{props.asset.stock.name}</Text>
        </View>
      </View>
      <View
        style={{
          marginTop: 10,
          flex: 1,
          flexDirection: "column",
        }}
      >
        <View style={{ alignItems: "flex-end" }}>
          <TextCurrency
            code="IDR"
            color="black"
            fontSize={10}
            value={props.asset.total_unit * props.asset.stock.price * 100}
          ></TextCurrency>

          <View>
            <Text style={{ fontSize: 12 }}>{props.asset.total_unit} Lot</Text>
          </View>
        </View>
      </View>
    </View>
  );
};
