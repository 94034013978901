import {
  View,
  TouchableOpacity,
  StyleSheet,
  Button,
  Image,
  ScrollView,
} from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../initSupabase";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../provider/AuthProvider";
import { useIsFocused } from "@react-navigation/native";
import Clipboard from "@react-native-clipboard/clipboard";
import {
  Layout,
  Text,
  TopNav,
  Section,
  SectionContent,
  TextInput,
  Picker,
} from "react-native-rapi-ui";
import { FontAwesome5 } from "@expo/vector-icons";
import "react-native-url-polyfill/auto";
import TextCurrency from "../components/TextCurrency";

const styles = StyleSheet.create({
  tinyLogo: {
    width: 30,
    height: 30,
  },
  verticleLine: {
    height: "100%",
    width: 1,
    backgroundColor: "#909090",
  },
});

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MainTabs">) {
  const isFocused = useIsFocused();
  const { user, session } = useContext(AuthContext);
  const [profile, setProfile] = useState({ balance: 0, member_id: "" });
  const [wallet, setWallet] = useState({ type: "OVO", phone: "xxxx" });
  const [edit, setEdit] = useState(false);
  const [walletType, setWalletType] = useState("OVO");
  const [walletPhone, setWalletPhone] = useState("");
  const [balanceProcess, setBalanceProcess] = useState(0);

  const wallets = [
    { label: "OVO", value: "OVO" },
    { label: "GOPAY", value: "GOPAY" },
    { label: "DANA", value: "DANA" },
  ];

  const fetchUserProfile = async () => {
    let { data: profile, error } = await supabase
      .from("user_profile")
      .select("balance,member_id")
      .eq("user_id", session?.user.id)
      .single();
    if (error) console.log("error", error);
    else setProfile(profile);
  };

  const saveUserWallet = async () => {
    let { data, error } = await supabase.from("user_wallet").upsert({
      type: walletType,
      phone: walletPhone,
      user_id: session?.user.id,
      id: wallet.id,
    });
    if (error) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  };

  const fetchUserWallet = async () => {
    let { data, error } = await supabase
      .from("wallets")
      .select("phone,type,id")
      .eq("user_id", session?.user.id)
      .limit(1)
      .single();
    if (!error) {
      setWallet(data);
    }
  };

  const fetchBalanceProcess = async () => {
    let { data, error } = await supabase.rpc("getBalanceProcess", {
      user_id: session.user.id,
    });
    if (error) console.error(error);
    else setBalanceProcess(data);
  };

  useEffect(() => {
    if (user) {
      fetchUserProfile();
      fetchUserWallet();
      fetchBalanceProcess();
    }
  }, [isFocused]);

  useEffect(() => {
    if (user) {
      fetchUserWallet();
    }
  }, [edit]);
  return (
    <Layout>
      <TopNav
        middleContent={
          <Image
            style={{ height: 150, width: 150 }}
            source={require("../../assets/icon.png")}
          ></Image>
        }
      />
      <Section style={{ marginHorizontal: 10, marginVertical: 10 }}>
        <SectionContent>
          <View
            style={{
              flexDirection: "row",
              flex: 2,
              marginVertical: 10,
              justifyContent: "space-between",
            }}
          >
            <View>
              <View style={{ marginBottom: 10 }}>
                <Text style={{ fontSize: 12 }}>Saldo bisa ditarik</Text>
              </View>
              <TextCurrency
                code="IDR"
                color="black"
                fontSize={12}
                value={profile.balance}
              ></TextCurrency>
            </View>
            <View
              style={{ flexDirection: "row", justifyContent: "space-evenly" }}
            >
              <View style={{ marginHorizontal: 10 }}>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("Withdraw");
                  }}
                >
                  <View style={{ alignItems: "center" }}>
                    <FontAwesome5
                      name="money-bill"
                      size={24}
                      color={"#1a91d6"}
                    ></FontAwesome5>
                    <Text style={{ fontSize: 12 }}>Tarik</Text>
                  </View>
                </TouchableOpacity>
              </View>

              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("History");
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                  }}
                >
                  <FontAwesome5
                    name="history"
                    size={24}
                    color={"#1a91d6"}
                  ></FontAwesome5>
                  <Text style={{ fontSize: 12 }}>Riwayat</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>

          <View
            style={{ justifyContent: "space-between", flexDirection: "row" }}
          >
            <Text style={{ fontSize: 12 }}>Saldo diproses</Text>
            <View style={{ flexDirection: "row" }}>
              <TextCurrency
                color="black"
                code="IDR"
                value={balanceProcess}
                fontSize={12}
              ></TextCurrency>
              <View style={{ marginHorizontal: 10 }}>
                <TouchableOpacity
                  disabled={balanceProcess == 0}
                  onPress={() => navigation.navigate("BalanceProcess")}
                >
                  <FontAwesome5 name="chevron-right" size={15}></FontAwesome5>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </SectionContent>
      </Section>
      <ScrollView>
        <Section style={{ marginHorizontal: 10, marginVertical: 10 }}>
          <SectionContent>
            <View style={{ marginBottom: 10 }}>
              <Text>Member </Text>
            </View>
            <TextInput
              read-only={true}
              value={profile.member_id}
              rightContent={
                <TouchableOpacity onPress={()=>{Clipboard.setString(profile.member_id)}}>
                  <FontAwesome5 name="copy" size={24} color="black" />
                </TouchableOpacity>
              }
            ></TextInput>
            <View style={{ marginVertical: 10 }}>
              <Text>Wallet </Text>
            </View>
            {!edit && (
              <TextInput
                rightContent={
                  <TouchableOpacity
                    onPress={() => {
                      setEdit(true);
                    }}
                  >
                    <Text>Ubah</Text>
                  </TouchableOpacity>
                }
                leftContent={<Text>{wallet.type}</Text>}
                read-only={true}
                value={wallet.phone}
              ></TextInput>
            )}
            {edit && (
              <>
                <View style={{ marginBottom: 10 }}>
                  <Picker
                    items={wallets}
                    value={walletType}
                    placeholder="Pilih Dompet"
                    onValueChange={(val) => setWalletType(val)}
                  />
                </View>
                <TextInput
                  style={{ flex: 1 }}
                  rightContent={
                    <TouchableOpacity
                      onPress={() => {
                        saveUserWallet();
                        setEdit(false);
                      }}
                    >
                      <Text>Simpan</Text>
                    </TouchableOpacity>
                  }
                  read-only={false}
                  onChangeText={(val) => setWalletPhone(val)}
                  value={walletPhone}
                ></TextInput>
              </>
            )}
          </SectionContent>
        </Section>
        <Section
          style={{
            marginVertical: 10,
            marginHorizontal: 10,
            padding: 10,
          }}
        >
          <Button
            color="#1a91d6"
            title="Keluar"
            onPress={async () => await supabase.auth.signOut()}
          />
        </Section>
      </ScrollView>
    </Layout>
  );
}
