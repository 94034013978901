import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import StockWithdraw from "../screens/StockWithdraw";
import StockDetail from "../screens/StockDetail";
import MainTabs from "./MainTabs";
import MerchantDetail from "../screens/MerchantDetail";
import Withdraw from "../screens/Withdraw";
import Reward from "../screens/Reward";
import Profile from "../screens/Profile";
import History from "../screens/History";
import BalanceProcess from "../screens/BalanceProcess";

const MainStack = createNativeStackNavigator();
const Main = () => {
  return (
    <MainStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <MainStack.Screen name="MainTabs" component={MainTabs} />
      <MainStack.Screen name="StockWithdraw" component={StockWithdraw} />
      <MainStack.Screen name="StockDetail" component={StockDetail} />
      <MainStack.Screen name="MerchantDetail" component={MerchantDetail} />
      <MainStack.Screen name="Withdraw" component={Withdraw} />
      <MainStack.Screen name="Reward" component={Reward} />
      <MainStack.Screen name="Profile" component={Profile} />
      <MainStack.Screen name="History" component={History} />
      <MainStack.Screen name="BalanceProcess" component={BalanceProcess} />
    </MainStack.Navigator>
  );
};

export default Main;
