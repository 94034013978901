import { MainStackParamList } from "../types/navigation";
import { useState, useContext, useEffect } from "react";
import { supabase } from "../initSupabase";
import { AuthContext } from "../provider/AuthProvider";
import { useIsFocused } from "@react-navigation/native";
import {
  Image,
  TouchableOpacity,
  FlatList,
  View,
  Text,
  StyleSheet,
} from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Layout, TopNav } from "react-native-rapi-ui";
import "react-native-url-polyfill/auto";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "Merchant">) {
  const isFocused = useIsFocused();
  const { user, session } = useContext(AuthContext);
  const [merchant, setMerchant] = useState([]);

  const styles = StyleSheet.create({
    logo: {
      width: 100,
      height: 100,
    },
  });

  const _handleClickAsset = (item) => {
    navigation.navigate("MerchantDetail", {
      id: item.id,
    });
  };

  const fetchUserReward = async () => {
    const ac = new AbortController();

    let { data: merchant, error } = await supabase
      .from("merchant")
      .select("*")
      .abortSignal(ac.signal);
    if (error) console.log("error", error);
    else setMerchant(merchant);
  };

  const _renderMerchantValue = ({ item }) => {
    return (
      <TouchableOpacity key={item.id} onPress={() => _handleClickAsset(item)}>
        <View
          style={{
            borderColor: "black",
            flexDirection: "row",
          }}
        >
          <Image
            style={styles.logo}
            resizeMode="center"
            source={{
              uri: item.icon,
            }}
          />
          <View style={{ flexDirection: "column", margin: 25 }}>
            <Text>{item.name}</Text>
            <Text>{"e-commerce"}</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const __separator = () => {
    return (
      <View
        style={{ width: "100%", backgroundColor: "#dcdcdc", height: 1 }}
      ></View>
    );
  };

  useEffect(() => {
    if (user) {
      fetchUserReward();
    }
  }, [isFocused]);

  return (
    <Layout>
      <TopNav
        middleContent={
          <Image
            style={{ height: 150, width: 150 }}
            source={require("../../assets/icon.png")}
          ></Image>
        }
      />

      <FlatList
        data={merchant}
        contentContainerStyle={{
          overflow: "hidden",
          padding: 10,
          margin: 10,
          borderRadius: 10,
          backgroundColor: "white",
        }}
        maximumZoomScale={2}
        ItemSeparatorComponent={__separator}
        renderItem={_renderMerchantValue}
        keyExtractor={(item) => item.id}
      />
    </Layout>
  );
}
