import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../provider/AuthProvider";
import TextCurrency from "../components/TextCurrency";
import { View, FlatList } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../initSupabase";
import Moment from "moment";
import {
  Layout,
  TopNav,
  Text,
  themeColor,
  Section,
  SectionContent,
} from "react-native-rapi-ui";
import { FontAwesome5 } from "@expo/vector-icons";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "BalanceProcess">) {
  const { user, session } = useContext(AuthContext);

  const [balanceProcess, setBalanceProcess] = useState([
    {
      total: 0,
      price: 0,
      total_unit: 0,
      created_at: "",
      stock: {
        code: "",
      },
    },
  ]);

  const _dateFormat = (date: Date) => {
    Moment.locale("en");
    let dt = date;
    return Moment(dt).format("DD MMM yyyy");
  };

  const fetchBalanceProcess = async () => {
    const { data: process, error } = await supabase
      .from("stock_withdraw")
      .select("id,total,price,total_unit,created_at,stock(code)")
      .eq("status", "IN_PROCESS");
    if (error) {
      console.log("error", error);
    } else {
      setBalanceProcess(process);
    }
  };

  const _renderValue = ({ item }) => {
    return (
      <Section key={item.id}>
        <SectionContent>
          <View
            style={{
              marginBottom: 10,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontSize: 12 }}>Tanggal</Text>
            <Text style={{ fontSize: 12 }}>{_dateFormat(item.created_at)}</Text>
          </View>
          <View
            style={{
              marginBottom: 10,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontSize: 12 }}>Saham</Text>
            <Text style={{ fontSize: 12 }}>{item.stock.code}</Text>
          </View>
          <View
            style={{
              marginBottom: 10,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontSize: 12 }}>Unit </Text>
            <Text style={{ fontSize: 12 }}>{item.total_unit} Lot</Text>
          </View>
          <View
            style={{
              marginBottom: 10,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontSize: 12 }}>Harga saham </Text>
            <Text style={{ fontSize: 12 }}>{item.price}</Text>
          </View>
          <View
            style={{
              marginBottom: 10,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontSize: 12 }}>Saldo diproses</Text>
            <TextCurrency
              code="IDR"
              color="black"
              fontSize={12}
              key={item.id}
              value={item.total}
            />
          </View>
        </SectionContent>
      </Section>
    );
  };

  const __separator = () => {
    return <View style={{ width: "100%", height: 10 }}></View>;
  };

  const _renderEmpty = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          minHeight: 60,
          paddingTop: 10,
          paddingBottom: 10,
          borderColor: "black",
        }}
      >
        <Text>Belum ada riwayat</Text>
      </View>
    );
  };
  useEffect(() => {
    if (user) {
      fetchBalanceProcess();
    }
  }, []);
  return (
    <Layout>
      <TopNav
        leftContent={
          <FontAwesome5
            name="chevron-left"
            size={20}
            color={themeColor.black100}
          />
        }
        leftAction={() => navigation.goBack()}
      />

      <FlatList
        contentContainerStyle={{
          overflow: "hidden",
          margin: 10,
          flexGrow: 1,
        }}
        
        data={balanceProcess}
        ItemSeparatorComponent={__separator}
        renderItem={_renderValue}
        ListEmptyComponent={_renderEmpty}
        keyExtractor={(item) => item.id}
      />
    </Layout>
  );
}
