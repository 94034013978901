import {
  View,
  Image,
  StyleSheet,
  Linking,
  Alert,
  TouchableOpacity,
} from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Layout,
  TopNav,
  Text,
  Section,
  SectionContent,
} from "react-native-rapi-ui";
import { FontAwesome5 } from "@expo/vector-icons";
import { useState, useContext, useEffect, useCallback } from "react";
import { AuthContext } from "../provider/AuthProvider";
import { supabase } from "../initSupabase";

import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

export default function ({
  route,
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MerchantDetail">) {
  const { id } = route.params;
  const { user, session } = useContext(AuthContext);
  const [merchant, setMerchant] = useState({});
  const styles = StyleSheet.create({
    logo: {
      width: 100,
      height: 100,
    },
  });

  const fetchUserReward = async () => {
    let { data: merchant, error } = await supabase
      .from("merchant")
      .select("*")
      .eq("id", id)
      .single();
    if (error) console.log("error", error);
    else setMerchant(merchant);
  };

  type OpenURLButtonProps = {
    url: string;
  };

  const OpenURLButton = ({ url }: OpenURLButtonProps) => {
    const handlePress = useCallback(async () => {
      // Checking if the link is supported for links with custom URL scheme.
      const supported = await Linking.canOpenURL(url);

      if (supported) {
        // Opening the link with some app, if the URL scheme is "http" the web link should be opened
        // by some browser in the mobile
        await Linking.openURL(url);
      } else {
        Alert.alert(`Don't know how to open this URL: ${url}`);
      }
    }, [url]);

    return (
      <TouchableOpacity onPress={handlePress} style={{ margin: 10 }}>
        <Text>
          <Text>{merchant.location}</Text>
        </Text>
      </TouchableOpacity>
    );
  };

  useEffect(() => {
    if (user) {
      fetchUserReward();
    }
  }, [id]);

  const Tab = createMaterialTopTabNavigator();

  return (
    <Layout>
      <TopNav
        middleContent={merchant.name}
        leftContent={
          <FontAwesome5 name="chevron-left" size={20} color={"black"} />
        }
        leftAction={() => navigation.goBack()}
      />
      <Section>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            style={styles.logo}
            resizeMode="center"
            source={{
              uri: merchant.icon,
            }}
          />
        </View>
        <View>
          <Tab.Navigator>
            <Tab.Screen name="Info">
              {() => (
                <Section>
                  <SectionContent>
                    <div
                      dangerouslySetInnerHTML={{ __html: merchant.description }}
                    />
                  </SectionContent>
                </Section>
              )}
            </Tab.Screen>
            <Tab.Screen name="Lokasi">
              {() => (
                <Section>
                  <SectionContent>
                    <Text style={{ margin: 10 }}>Online : </Text>
                    <OpenURLButton url={merchant.location}></OpenURLButton>
                  </SectionContent>
                </Section>
              )}
            </Tab.Screen>
          </Tab.Navigator>
        </View>
      </Section>
    </Layout>
  );
}
