import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../provider/AuthProvider";
import TextCurrency from "../components/TextCurrency";
import { View } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../initSupabase";
import {
  Layout,
  TopNav,
  Text,
  Avatar,
  themeColor,
  Section,
  SectionContent,
  Button,
} from "react-native-rapi-ui";
import { FontAwesome5 } from "@expo/vector-icons";

export default function ({
  route,
  navigation,
}: NativeStackScreenProps<MainStackParamList, "StockDetail">) {
  const { itemId } = route.params;
  const { user, session } = useContext(AuthContext);

  const [userAsset, setUserAsset] = useState({
    total_unit: 0,
    stock: {
      name: "",
      code: "",
      icon: "",
    },
  });
  const [assetPrice, setAssetPrice] = useState(0);

  const fetchUserAsset = async () => {
    const { data: userAsset, error } = await supabase
      .from("user_stock")
      .select("*,stock(code,name,icon)")
      .eq("id", itemId)
      .single();
    if (error) {
      console.log("error", error);
    } else {
      setUserAsset(userAsset!);
      fetchAssetPrice(userAsset?.asset_id);
    }
  };

  const fetchAssetPrice = async (id) => {
    const { data: price, error } = await supabase
      .from("stock_price")
      .select("price")
      .eq("stock_id", id)
      .single();

    if (error) {
      console.log("error", error);
    } else {
      setAssetPrice(price!.price);
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserAsset();
    }
  }, [itemId]);
  return (
    <Layout>
      <TopNav
        middleContent={"Saham Detail"}
        leftContent={
          <FontAwesome5
            name="chevron-left"
            size={20}
            color={themeColor.black100}
          />
        }
        leftAction={() => navigation.goBack()}
      />
      <View>
        <View
          style={{
            marginRight: 12,
            marginLeft: 12,
            marginTop: 12,
            minHeight: "10%",
          }}
        >
          <Section>
            <SectionContent>
              <View
                style={{ flexDirection: "row", justifyContent: "flex-start" }}
              >
                <Avatar
                  style={{ marginHorizontal: 5 }}
                  source={{ uri: userAsset?.stock?.icon }}
                  size="sm"
                  shape="round"
                />
                <View
                  style={{
                    flex: 4,
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <Text style={{ fontSize: 12 }}>{userAsset?.stock?.code}</Text>
                  <Text>{userAsset?.stock?.name}</Text>
                </View>
              </View>
            </SectionContent>
          </Section>
        </View>

        <View
          style={{
            marginRight: 12,
            marginLeft: 12,
            marginTop: 12,
            minHeight: "10%",
          }}
        >
          <Section>
            <SectionContent>
              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 12,
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 12 }}>Lot Dimiliki</Text>
                <Text style={{ fontSize: 12 }}>{userAsset?.total_unit}</Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 12,
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 12 }}>Harga saham</Text>
                <TextCurrency
                  color={"black"}
                  value={assetPrice}
                  code="IDR"
                  fontSize={12}
                ></TextCurrency>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 12,
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 12 }}>Total</Text>
                <TextCurrency
                  color={"black"}
                  value={Math.floor(assetPrice * 100 * userAsset?.total_unit)}
                  code="IDR"
                  fontSize={12}
                ></TextCurrency>
              </View>
              <View style={{ marginVertical: 10 }}>
                <Button
                  color="#1a91d6"
                  disabled={userAsset?.total_unit >= 1 ? false : true}
                  text="Tarik"
                  onPress={() => {
                    navigation.navigate("StockWithdraw", {
                      itemId: itemId,
                    });
                  }}
                ></Button>
              </View>
            </SectionContent>
          </Section>
        </View>
      </View>
    </Layout>
  );
}
