import { MainStackParamList } from "../types/navigation";
import { useState, useContext, useEffect } from "react";
import { supabase } from "../initSupabase";
import { AuthContext } from "../provider/AuthProvider";
import { useIsFocused } from "@react-navigation/native";
import RewardItem from "../components/RewardItem";
import { Text, TouchableOpacity, FlatList, View,Image } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Layout, Section, SectionContent, TopNav } from "react-native-rapi-ui";

import "react-native-url-polyfill/auto";
import { FontAwesome5 } from "@expo/vector-icons";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MainTabs">) {
  const { user, session } = useContext(AuthContext);
  const isFocused = useIsFocused();
  const [userReward, setUserReward] = useState([]);
  const fetchUserReward = async () => {
    const ac = new AbortController();

    let { data: userReward, error } = await supabase
      .from("user_reward")
      .select(
        "id,transaction_id,created_at,total_reward,total_unit,stock(code,icon),merchant(name)"
      )
      .abortSignal(ac.signal);
    if (error) console.log("error", error);
    else setUserReward(userReward);
  };

  useEffect(() => {
    if (user) {
      fetchUserReward();
    }
  }, [isFocused]);

  const _renderRewardValue = ({ item }) => {
    return (
      <Section key={item.id}>
        <SectionContent>
          <RewardItem key={item.id} asset={item}></RewardItem>
        </SectionContent>
      </Section>
    );
  };

  const __separator = () => {
    return <View style={{ width: "100%", height: 10 }}></View>;
  };

  const _renderEmpty = () => {
    return (
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: 300,
          paddingTop: 10,
          paddingBottom: 10,
          borderColor: "black",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("Merchant");
          }}
        >
          <FontAwesome5 name="shopping-cart" size={48} color="#1a91d6" />
          <Text>Ayo Belanja</Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <Layout>
        <TopNav
        middleContent={
          <Image
            style={{ height: 150, width: 150 }}
            source={require("../../assets/icon.png")}
          ></Image>
        }
      />
      <FlatList
        data={userReward}
        contentContainerStyle={{
          overflow: "hidden",
          marginHorizontal: 5,
          marginVertical: 5,
        }}
        ItemSeparatorComponent={__separator}
        renderItem={_renderRewardValue}
        ListEmptyComponent={_renderEmpty}
        keyExtractor={(item) => item.id}
      />
    </Layout>
  );
}
