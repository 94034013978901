import React from "react";
import {
  Text
} from "react-native-rapi-ui";
import {
  formatCurrency,
} from "react-native-format-currency";
import 'react-native-url-polyfill/auto';

export default (props: { value: number | 0; code: string | 'IDR';fontSize:number | 12,color:string |'black' })=>{
  const [valueFormattedWithSymbol, valueFormattedWithoutSymbol, symbol] = formatCurrency({ amount: Number(props.value), code: props.code })
  return (
        <>
          <Text style={{fontWeight: "bold",fontSize:props.fontSize,color:props.color}}>{valueFormattedWithSymbol}</Text>
        </>
    );
};
