import React from "react";
import Moment from "moment";
import { View } from "react-native";
import { Text, Avatar } from "react-native-rapi-ui";
import "react-native-url-polyfill/auto";
import TextCurrency from "./TextCurrency";

export default (props: {
  history: {
    amount: number;
    status: string;
    created_at: Date;
    type_trx: string;
  };
}) => {
  const _dateFormat = (date: Date) => {
    Moment.locale("en");
    let dt = date;
    return Moment(dt).format("DD MMM yyyy");
  };
  return (
    <View
      style={{
        flex: 3,
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <View
          style={{
            flex: 2,
            marginTop: 10,
            flexDirection: "column",
          }}
        >
          <View>
            <Text style={{ fontSize: 12 }}>
              {_dateFormat(props.history.created_at)}
            </Text>
            <Text style={{ fontSize: 10 }}>{props.history.type_trx}</Text>
          </View>
        </View>
        <View
          style={{
            marginTop: 10,
            flex: 2,
            flexDirection: "column",
          }}
        >
          <View style={{ alignItems: "flex-end" }}>
            <Text style={{ fontSize: 10 }}>{props.history.status}</Text>
            <View>
              <TextCurrency
                value={props.history.amount}
                code="IDR"
                fontSize={12}
                color="black"
              ></TextCurrency>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
