import React from "react";
import { FontAwesome5 } from "@expo/vector-icons";

export default ({ icon, focused }: { icon: any; focused: boolean }) => {
  return (
    <FontAwesome5
      name={icon}
      style={{ marginBottom: -7 }}
      size={24}
      color={focused ? "#1a91d6" : "rgb(143, 155, 179)"}
    />
  );
};
